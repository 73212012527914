import { ProfileV2 } from 'types/kyc/Profile';
import { Profile } from './types';

export const isDraft = (profile?: Partial<Profile>) =>
  profile?.meta?.state === 'draft';

export const isDraftV2 = (profile?: Partial<ProfileV2>) =>
  profile?.state === 'created';

export const isPending = (profile?: Partial<Profile>) =>
  profile?.meta?.state === 'submitted' ||
  (profile?.meta?.state === 'pending' && profile?.meta?.outcome === 'none');

export const isPendingV2 = (profile?: Partial<ProfileV2>) =>
  profile?.state === 'submitted';

export const isApproved = (profile?: Partial<Profile>) =>
  profile?.meta?.outcome === 'approved';

export const isApprovedV2 = (profile?: Partial<ProfileV2>) =>
  profile?.state === 'approved';

export const isRejected = (profile?: Partial<Profile>) =>
  profile?.meta?.outcome === 'rejected';

export const isRejectedV2 = (profile?: Partial<ProfileV2>) =>
  profile?.state === 'rejected';

export const isBlocked = (profile?: Partial<Profile>) => {
  return profile?.meta?.blocked === true;
};

export const isInfoRequired = (profile?: Partial<ProfileV2>) =>
  profile?.state === 'info_required';

export const isKindEditable = (profileVersions: Profile[]) =>
  !profileVersions.find(
    (p) => p.meta?.state === 'confirmed' || p.meta?.outcome === 'approved',
  );

export const isDirector = (personId: string, profile: Partial<ProfileV2>) =>
  profile.directors?.find((d) => d.person.id === personId);

export const isBeneficialOwner = (
  personId: string,
  profile: Partial<ProfileV2>,
) => profile.beneficialOwners?.find((d) => d.person.id === personId);

export const removeUnwantedAttributes = (
  profile: Partial<ProfileV2>,
): Partial<ProfileV2> => {
  const unwantedKeys: (keyof ProfileV2)[] = [
    'state',
    'updatedAt',
    'updatedBy',
    'users',
  ];
  const cleanedProfile: Partial<ProfileV2> = { ...profile };
  for (const key of unwantedKeys) {
    delete cleanedProfile[key];
  }

  return cleanedProfile;
};
