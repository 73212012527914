import rest from 'services/rest';
import { PersonKind, RoleArray } from 'types/kyc/Person';
import {
  CorporateDetails,
  CorporateForm,
  PersonalForm,
  Profile,
  ProfileKind,
  ProfileV2,
} from 'types/kyc/Profile';
import { NewVerification, Verification } from 'types/kyc/Verification';
import { ProfilesResponse } from './types';

const list = (): Promise<Profile[]> => rest.get(`/api/iam/profiles`);

const read = (id: string): Promise<Profile> =>
  rest.get(`/api/kyc/profiles/${id}`);

const readV2 = (id: string): Promise<ProfileV2 | void> =>
  rest.get(`/api/profiles?id=${id}`).then((response) => {
    if ((response as ProfilesResponse).total) {
      return (response as ProfilesResponse).profiles[0];
    }
    return undefined;
  });

/**
 * removePerson removes a person from corporate profile
 * @param {string} profileId Profile ID
 * @param {string} personId Person ID
 */
const removePerson = (profileId: string, personId: string): Promise<Response> =>
  rest.del(`/api/profiles/${profileId}/persons/${personId}`);

const store = (id: string, data: Partial<Profile>): Promise<Profile> =>
  rest.patch(`/api/kyc/profiles/${id}`, data);

const create = (kind: ProfileKind, profileId: string): Promise<ProfileV2> =>
  rest.post(`/api/profiles`, { kind, id: profileId });

const storeV2 = (id: string, data: Partial<ProfileV2>): Promise<ProfileV2> =>
  rest.put(`/api/profiles/${id}`, data);

const storeDetails = (
  profileId: string,
  data: Partial<CorporateDetails>,
): Promise<ProfileV2> =>
  rest.put(`/api/profiles/${profileId}/details`, { corporate: data });

const storeForm = (
  profileId: string,
  kind: ProfileKind,
  data: Partial<CorporateForm | PersonalForm>,
): Promise<ProfileV2> =>
  rest.put(`/api/profiles/${profileId}/form`, { [kind]: data });

const storeRoles = (profileId: string, data: RoleArray): Promise<Profile> =>
  rest.post(`/api/profiles/${profileId}/persons`, data);

const removeRole = (
  profileId: string,
  personId: string,
  role: PersonKind,
): Promise<Response> =>
  rest.del(`/api/profiles/${profileId}/persons/${personId}/roles/${role}`);

const updateOwnership = (
  profileId: string,
  personId: string,
  ownershipPercentage: number,
): Promise<ProfileV2> =>
  rest.put(
    `/api/profiles/${profileId}/persons/${personId}/roles/beneficialOwner`,
    {
      ownershipPercentage,
    },
  );

const submit = (profileId: string) =>
  rest.patch(`/api/profiles/${profileId}`, { state: 'submitted' });

const createVerifications = (
  profileId: string,
  data: NewVerification[],
): Promise<Profile> =>
  rest.post(`/api/profiles/${profileId}/verifications`, {
    verifications: data,
  });

const updateVerification = (
  profileId: string,
  verification: Verification,
): Promise<Profile> =>
  rest.put(
    `/api/profiles/${profileId}/verifications/${verification.id}`,
    verification,
  );

/**
 * createVersion creates a profile version
 * @param {string} id Profile ID
 */
const createVersion = (id: string): Promise<Profile> =>
  rest.post(`/api/kyc/profiles/${id}/versions`);

/**
 * Lists a versions for a profile
 * @param id Profile id
 * @returns list of all versions for this profile
 */
const versions = (id: string): Promise<Profile[]> =>
  rest.get(`/api/kyc/profiles/${id}/versions`);

export default {
  list,
  create,
  read,
  readV2,
  removePerson,
  store,
  storeV2,
  storeDetails,
  storeForm,
  storeRoles,
  removeRole,
  submit,
  createVerifications,
  updateVerification,
  updateOwnership,
  createVersion,
  versions,
};
