import type { AppThunk } from 'customer/store/configureStore';
import ValidationError from 'src/utils/error';
import { PersonKind, RoleArray } from 'types/kyc/Person';
import {
  CorporateDetails,
  CorporateForm,
  PersonalForm,
  Profile,
  ProfileKind,
  ProfileV2,
} from 'types/kyc/Profile';
import { NewVerification, Verification } from 'types/kyc/Verification';
import { ValidationErrors } from 'types/validationTypes';
import { alertFailure } from '../../notification/Alert/actions';
import userActions from '../User/actions';
import {
  CREATE_VERSION_FAILURE,
  CREATE_VERSION_REQUEST,
  CREATE_VERSION_SUCCESS,
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  PROFILE_KIND_CORPORATE,
  PROFILE_KIND_PERSONAL,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  REMOVE_PERSON_FAILURE,
  REMOVE_PERSON_REQUEST,
  REMOVE_PERSON_SUCCESS,
  REMOVE_ROLE_FAILURE,
  REMOVE_ROLE_REQUEST,
  REMOVE_ROLE_SUCCESS,
  RESET_FAILURE,
  RESET_REQUEST,
  RESET_SUCCESS,
  STORE_DETAILS_FAILURE,
  STORE_DETAILS_REQUEST,
  STORE_DETAILS_SUCCESS,
  STORE_FAILURE,
  STORE_FORM_FAILURE,
  STORE_FORM_REQUEST,
  STORE_FORM_SUCCESS,
  STORE_REQUEST,
  STORE_ROLES_FAILURE,
  STORE_ROLES_REQUEST,
  STORE_ROLES_SUCCESS,
  STORE_SUCCESS,
  STORE_VERIFICATIONS_FAILURE,
  STORE_VERIFICATIONS_REQUEST,
  STORE_VERIFICATIONS_SUCCESS,
  VERSIONS_FAILURE,
  VERSIONS_REQUEST,
  VERSIONS_SUCCESS,
} from './constants';
import service from './service';
import { validateCorporate, validatePersonal } from './validation';

const list = (): AppThunk<Promise<Profile[] | void>> => {
  const request = () => ({ type: LIST_REQUEST });
  const success = (l: Profile[]) => ({
    type: LIST_SUCCESS,
    list: l,
  });
  const failure = (error?: ValidationErrors) => ({
    type: LIST_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const l = await service.list();
      dispatch(success(l));
      return l;
    } catch (error) {
      dispatch(alertFailure(<ValidationError>error, failure));
      return undefined;
    }
  };
};

const create = (
  kind: ProfileKind,
  profileId: string,
): AppThunk<Promise<ProfileV2 | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: STORE_REQUEST, item: { kind, profileId }, error };
  };
  const success = ({ item }: { item: ProfileV2 }) => ({
    type: STORE_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.create(kind, profileId);
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const read = (id: string): AppThunk<Promise<Profile | void>> => {
  const request = () => ({ type: READ_REQUEST });
  const success = (item: Profile) => ({
    type: READ_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({ type: READ_FAILURE, error });

  return async (dispatch) => {
    dispatch(request());
    try {
      const item = await service.read(id);
      dispatch(success(item));
      return item;
    } catch (error) {
      if (error) dispatch(alertFailure(<ValidationError>error, failure));
      return undefined;
    }
  };
};

const readV2 = (id: string): AppThunk<Promise<ProfileV2 | void>> => {
  const request = () => ({ type: READ_REQUEST });
  const success = (item: Profile) => ({
    type: READ_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({ type: READ_FAILURE, error });

  return async (dispatch) => {
    dispatch(request());
    try {
      const item = await service.readV2(id);
      if (item) {
        dispatch(success(item));
        return item;
      }
    } catch (error) {
      if (error) dispatch(alertFailure(<ValidationError>error, failure));
      throw error;
    }
  };
};

export const hasActivity = (p: Partial<Profile>): boolean => {
  return !!Object.keys(p || {}).find((k) => k.substring(0, 8) === 'activity');
};

const store = (
  profileId: string,
  data: Partial<Profile>,
): AppThunk<Promise<Profile | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: STORE_REQUEST, item: data, error };
  };
  const success = ({ item }: { item: Profile }) => ({
    type: STORE_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.store(profileId, data);
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const storeV2 = (
  profileId: string,
  data: Partial<ProfileV2>,
): AppThunk<Promise<ProfileV2 | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: STORE_REQUEST, item: data, error };
  };
  const success = ({ item }: { item: ProfileV2 }) => ({
    type: STORE_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.storeV2(profileId, data);
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const storeDetails = (
  profileId: string,
  data: Partial<CorporateDetails>,
): AppThunk<Promise<Profile | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: STORE_DETAILS_REQUEST, item: data, error };
  };
  const success = ({ item }: { item: Profile }) => ({
    type: STORE_DETAILS_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_DETAILS_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.storeDetails(profileId, data);
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const storeForm = (
  profileId: string,
  kind: ProfileKind,
  data: Partial<CorporateForm | PersonalForm>,
): AppThunk<Promise<ProfileV2 | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: STORE_FORM_REQUEST, item: data, error };
  };
  const success = ({ item }: { item: ProfileV2 }) => ({
    type: STORE_FORM_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_FORM_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.storeForm(profileId, kind, data);
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const storeRoles = (
  profileId: string,
  data: RoleArray,
): AppThunk<Promise<Profile | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: STORE_ROLES_REQUEST, item: data, error };
  };
  const success = ({ item }: { item: Profile }) => ({
    type: STORE_ROLES_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_ROLES_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.storeRoles(profileId, data);
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const updateOwnership = (
  profileId: string,
  personId: string,
  ownershipPercentage: number,
): AppThunk<Promise<Profile | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: STORE_ROLES_REQUEST, item: ownershipPercentage, error };
  };
  const success = ({ item }: { item: Profile }) => ({
    type: STORE_ROLES_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_ROLES_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.updateOwnership(
        profileId,
        personId,
        ownershipPercentage,
      );
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const removeRole = (
  profileId: string,
  personId: string,
  role: PersonKind,
): AppThunk<Promise<Response | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: REMOVE_ROLE_REQUEST, item: Response, error };
  };
  const success = () => ({
    type: REMOVE_ROLE_SUCCESS,
  });
  const failure = (error?: ValidationErrors) => ({
    type: REMOVE_ROLE_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.removeRole(profileId, personId, role);
      dispatch(success());
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const createVerifications = (
  profileId: string,
  data: NewVerification[],
): AppThunk<Promise<Profile | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: STORE_VERIFICATIONS_REQUEST, item: data, error };
  };
  const success = ({ item }: { item: Profile }) => ({
    type: STORE_VERIFICATIONS_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_VERIFICATIONS_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.createVerifications(profileId, data);
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const updateVerification = (
  profileId: string,
  verification: Verification,
): AppThunk<Promise<Profile | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: STORE_VERIFICATIONS_REQUEST, item: verification, error };
  };
  const success = ({ item }: { item: Profile }) => ({
    type: STORE_VERIFICATIONS_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: STORE_VERIFICATIONS_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.updateVerification(
        profileId,
        verification,
      );
      dispatch(success({ item: response }));
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const createVersion = (
  profileId: string,
): AppThunk<Promise<Profile | void>> => {
  const request = () => ({ type: CREATE_VERSION_REQUEST });
  const success = (item: Profile) => ({
    type: CREATE_VERSION_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: CREATE_VERSION_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const p = await service.read(profileId);
      switch (p?.kind) {
        case PROFILE_KIND_PERSONAL: {
          const valid = validatePersonal(p);
          if (!valid.isValid) {
            dispatch(
              alertFailure(ValidationError.parseErrors(valid.errors), failure),
            );
          }
          const userVersion = await dispatch(userActions.createVersion());
          if (!userVersion || !valid.isValid) {
            return undefined;
          }

          const version = await service
            .createVersion(<string>p.id)
            .then((profile) => {
              dispatch(success(profile));
              return profile;
            });

          return version;
        }
        case PROFILE_KIND_CORPORATE: {
          const valid = validateCorporate(p);
          if (!valid.isValid) {
            dispatch(
              alertFailure(ValidationError.parseErrors(valid.errors), failure),
            );
          }
          const userVersion = await dispatch(userActions.createVersion());
          if (!userVersion || !valid.isValid) {
            return undefined;
          }

          const version = await service
            .createVersion(<string>p.id)
            .then((profile) => {
              dispatch(success(profile));
              return profile;
            });

          return version;
        }
        default: {
          dispatch(
            alertFailure(
              ValidationError.parseErrors({
                kind: 'Please select what type of profile you want to create',
              }),
              failure,
            ),
          );
          return undefined;
        }
      }
    } catch (error) {
      if (error) dispatch(alertFailure(error, failure));
      return undefined;
    }
  };
};

const versions = (id: string): AppThunk<Promise<Profile[] | void>> => {
  const request = () => ({ type: VERSIONS_REQUEST });
  const success = (l: Profile[]) => ({
    type: VERSIONS_SUCCESS,
    versions: l,
  });
  const failure = (error?: ValidationErrors) => ({
    type: VERSIONS_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const l = await service.versions(id);
      dispatch(success(l));
      return l;
    } catch (error) {
      dispatch(alertFailure(<ValidationError>error, failure));
      return undefined;
    }
  };
};

const removePerson = (
  profileId: string,
  personId: string,
): AppThunk<Promise<Response | void>> => {
  const request = (error?: ValidationErrors) => {
    return { type: REMOVE_PERSON_REQUEST, item: personId, error };
  };
  const success = () => ({
    type: REMOVE_PERSON_SUCCESS,
  });
  const failure = (error?: ValidationErrors) => ({
    type: REMOVE_PERSON_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const response = await service.removePerson(profileId, personId);
      dispatch(success());
      return response;
    } catch (error) {
      if (error instanceof ValidationError) {
        dispatch(alertFailure(error, failure));
      }
      throw error;
    }
  };
};

const reset = (profileId: string, data: Partial<Profile>): AppThunk => {
  const request = () => ({ type: RESET_REQUEST });
  const success = (item: Profile) => ({
    type: RESET_SUCCESS,
    item,
  });
  const failure = (error?: ValidationErrors) => ({
    type: RESET_FAILURE,
    error,
  });

  return async (dispatch) => {
    dispatch(request());
    try {
      const item = { id: profileId, ...data } as Profile;
      dispatch(success(item));
    } catch (error) {
      if (error) dispatch(alertFailure(<ValidationError>error, failure));
    }
  };
};

export default {
  list,
  create,
  read,
  readV2,
  store,
  storeV2,
  storeDetails,
  storeForm,
  storeRoles,
  removeRole,
  createVerifications,
  updateOwnership,
  updateVerification,
  createVersion,
  versions,
  removePerson,
  reset,
};
