import { Verification } from 'types/kyc/Verification';

export const isApproved = (verifications?: Verification[]): boolean => {
  return !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.outcome === 'approved',
  );
};

export const isApprovedV2 = (verifications?: Verification[]): boolean => {
  return !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.state === 'approved',
  );
};

export const isRejected = (verifications?: Verification[]): boolean => {
  return !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.outcome === 'rejected',
  );
};

export const isRejectedV2 = (verifications?: Verification[]): boolean => {
  return !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.state === 'rejected',
  );
};

export const validateMoneriumProofOfResidencyVerification = (
  verifications?: Verification[],
): boolean => {
  return !verifications?.find(
    (v) =>
      (v.kind === 'proofOfResidency' && v.outcome === 'approved') ||
      (v.kind === 'proofOfResidency' &&
        v.verifier === 'monerium' &&
        v.outcome !== 'rejected'),
  );
};

export const validateMoneriumProofOfResidencyVerificationV2 = (
  verifications?: Verification[],
): boolean => {
  return !verifications?.find(
    (v) =>
      (v.kind === 'proofOfResidency' && v.state === 'approved') ||
      (v.kind === 'proofOfResidency' &&
        v.verifier === 'monerium' &&
        v.state !== 'rejected'),
  );
};

export const skipMoneriumProofOfResidencyVerification = (
  verifications?: Verification[],
): boolean => {
  const hasMoneriumVerification = !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.verifier === 'monerium',
  );

  const isMoneriumRejected = !!verifications?.find(
    (v) =>
      v.kind === 'proofOfResidency' &&
      v.verifier === 'monerium' &&
      v.outcome === 'rejected',
  );

  const isOnfidoApproved = !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.outcome === 'approved',
  );

  return isOnfidoApproved || (hasMoneriumVerification && !isMoneriumRejected);
};

export const skipMoneriumProofOfResidencyVerificationV2 = (
  verifications?: Verification[],
): boolean => {
  const hasMoneriumVerification = !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.verifier === 'monerium',
  );

  const isMoneriumRejected = !!verifications?.find(
    (v) =>
      v.kind === 'proofOfResidency' &&
      v.verifier === 'monerium' &&
      v.state === 'rejected',
  );

  const isOnfidoApproved = !!verifications?.find(
    (v) => v.kind === 'proofOfResidency' && v.state === 'approved',
  );

  return isOnfidoApproved || (hasMoneriumVerification && !isMoneriumRejected);
};

export default {
  skipMoneriumProofOfResidencyVerification,
  skipMoneriumProofOfResidencyVerificationV2,
  validateMoneriumProofOfResidencyVerification,
  validateMoneriumProofOfResidencyVerificationV2,
  isApproved,
  isApprovedV2,
  isRejected,
  isRejectedV2,
};
